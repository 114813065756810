<template>
  <div class="">
    <section style="display:flex; flex-flow:row nowrap; gap:10px;">
      <button class="button is-primary">Gem ændringer</button>
      <button class="button is-light">Slet opgaven</button>
      <button class="button is-dark">Opret nyt tilbud</button>
    </section>

    <section>
      <div class="field">
        <label class="label">Label text</label>
        <div class="control">
          <input class="input is-light" type="text" placeholder="Placeholder text" />
        </div>
      </div>
      <div class="field">
        <label class="label">Antal arbejdstimer (valgfri)</label>
        <div class="control">
          <input class="input is-danger" type="text" placeholder="Placeholder text" />
        </div>
      </div>
    </section>

    <section>
      <h1>
        &lt;h1&gt; The quick brown fox jumps over the lazy dog.
      </h1>
      <h2>
        &lt;h2&gt; The quick brown fox jumps over the lazy dog.
      </h2>
      <p>
        A paragraph of regular text - the quick brown fox jumps over the lazy dog.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'dev-components-view',

}
</script>

<style lang="scss">
  section {
    padding: 20px;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
  }
</style>
